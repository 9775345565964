@import '../../theme/src/base/_cx-bootstrap-variables.scss';

.cx-switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing-xs 0;
  margin-bottom: $spacing-m;

  label {
    padding-top: 2px;
  }

  input {
    position: absolute;
    visibility: hidden;
    width: 0;
    height: 0;
  }

  &__knob {
    position: relative;
    border: 1px solid $gray600;
    box-sizing: border-box;
    border-radius: $border-radius-large;
    width: 36px;
    height: 16px;
    padding-bottom: $spacing-xs;
    transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);

    &:hover {
      cursor: pointer;
    }

    &:after {
      content: "";
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      background-color: $white;
      border: 1px solid $gray600;
      position: absolute;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      top: -5px;
      left: -1px;
      transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);

      &:hover {
        cursor: pointer;
      }
    }
  }

  input:checked ~ &__knob {
    background-color: $blue500;
    border: 1px solid $blue500;

    &:after {
      left: 13px;
      top: -5px;
    }
  }

  input:disabled ~ &__knob {
    opacity: $opacity-disabled;
    cursor: not-allowed;
  }
}

/** IE 11 fix */
_:-ms-fullscreen,
:root .cx-switch input {
  display: none;
}
