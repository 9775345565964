@import '../../theme/src/base/_cx-bootstrap-variables.scss';

$cname: "#{$uiPrefix}loadingIndicator";

.#{$cname} {
  &__dots {
    display: flex;
    justify-content: center;
  }

  &__dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $grey670;
    transform: scale(0.5);
    animation: scale-dot 1.2s ease-out infinite;

    &:nth-of-type(2) {
      animation-delay: 0.2s;
    }

    &:nth-of-type(3) {
      animation-delay: 0.3s;
    }

    &:nth-of-type(4) {
      animation-delay: 0.4s;
    }

    &:nth-of-type(5) {
      animation-delay: 0.5s;
    }
  }

  @keyframes scale-dot {
    0% {
      transform: scale(0.5);
      opacity: 0.25;
    }

    25% {
      transform: scale(0.75);
      opacity: 1;
    }

    75% {
      transform: scale(0.5);
      opacity: 0.5;
    }

    100% {
      transform: scale(0.5);
      opacity: 0.25;
    }
  }

  &--white &__dot {
    background: $white;
  }

  &--large &__dot {
    margin-right: -1px;
    width: 20px;
    height: 20px;
  }

  &--small &__dot {
    margin-right: 1px;
    width: 5px;
    height: 5px;
  }

  &--left &__dots {
    justify-content: flex-start;
  }

  &--right &__dots {
    justify-content: flex-end;
  }
}

.#{$bsPrefix}btn.btn--loader {
  .#{$cname} {
    margin: -2px $spacing-s 0 0;
    display: inline-block;
    vertical-align: middle;
  }
}
