@import '../../../theme/src/base/_cx-bootstrap-variables.scss';

.iconblock__item {
  display: inline-flex;
  align-items: center;

  .svgicon {
    margin-right: $spacing-xs;
  }
}

.svgicon {
  display: inline-block;
  height: 24px;
  width: 24px;
  fill: currentColor;

  &.svgicon--active {
    fill: $blue500;
  }
}
