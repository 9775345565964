@import '../../../theme/src/base/_cx-bootstrap-variables.scss';

.help-block__wrapper {
  display: flex;

  svg {
    margin-left: -4px;
    margin-right: $spacing-xxs;
  }

  .icon-error {
    color: $red500;
  }

  .icon-warning {
    color: $orange-ext2;

    .IconWarning__exclamation {
      fill: $gray-base !important;
    }
  }
}
